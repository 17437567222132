import React from 'react';
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import WishboxBg from "../images/bg/wishbox-no-borders@1x.jpg";
import Wishbox from "../images/wishbox-title.svg";
import MyDream from "../images/my-dream.svg";
import Axios from "axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import cross from "../images/icon-cross.svg";

const WishBox = () => {
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <html lang='ru'/>
        <title>{t('lead.seo.wishbox')}</title>
      </Helmet>
      <section className="wishbox">
        <div className="wishbox__wrapper">
          <div className="wishbox__image-wrapper">
            <img src={WishboxBg} alt="Желание"/>
          </div>
          <div className="wishbox__content-wrapper">
            <div className="wishbox__title-wrapper">
              <img src={Wishbox} alt="Wishbox Ampm"/>
            </div>
            <div className="wishbox__form-wrapper">
              <img src={MyDream} alt="Моя мечта"/>
              <Formik
                initialValues={{
                  user_name: "",
                  user_wish: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.user_name) {
                    errors.name = "Напиши свое имя!";
                  }
                  if (!values.user_wish) {
                    errors.message = "Не забудь загадать желание.";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm, initialValues }) => {
                  resetForm({ values: initialValues });
                  setTimeout(() => {
                    const axiosConfig = {
                      headers: {
                        "Content-type": "application/json",
                      },
                    };
                    Axios.post(
                      "https://general.aquaart.tech/api/wishbox/myWish",
                      values,
                      axiosConfig
                    )
                      .catch((e) => {
                        console.log(e);
                      })
                      .finally(() => {
                        console.log("Данные прошли!");
                        setSubmitting(false);
                        document
                          .querySelector(".modal-wrapper")
                          .classList.add("modal-wrapper--active");
                        document
                          .querySelector(".modal__close")
                          .addEventListener("click", function () {
                            document
                              .querySelector(".modal-wrapper")
                              .classList.remove("modal-wrapper--active");
                          });
                      });
                  }, 400);
                }}
              >
                {({isSubmitting}) => (
                  <Form>
                    <div className="wishbox-form">
                      <div className="wishbox-form__row wishbox-form__row--input">
                        <label htmlFor="user_name">
                          <p className="wishbox-form__label-text">
                            {t('lead.wishbox.name')}
                          </p>
                          <Field type="text" name="user_name"/>
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error-div"
                          />
                        </label>
                      </div>
                      <div className="wishbox-form__row wishbox-form__row--textarea">
                        <label htmlFor="message">
                          <p className="wishbox-form__label-text">
                            {t('lead.wishbox.wish')}
                          </p>
                          <Field type="text" as="textarea" name="user_wish"/>
                          <ErrorMessage
                            name="user_wish"
                            component="div"
                            className="error-div"
                          />
                        </label>
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="wishbox-form__submit"
                      >
                        {t('lead.wishbox.submit')}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>


        </div>
      </section>
      <footer className="dream-bigger">
        <p>Dream bigger — Do now</p>
      </footer>
      <div className="modal-wrapper">
        <div className="modal">
          <button className="modal__close">
            <img src={cross} alt="Закрыть форму" title="Закрыть форму" />
          </button>
          <p>Мечта отправлена!</p>
        </div>
      </div>
    </>

  );
};

export default WishBox;
